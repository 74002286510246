import { Fragment, useState,useCallBack } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import PopUp from './PopUp'
import SeeMore from './SeeMore'
import { useEffect } from 'react'
export default function Example(props) {
  const [open, setOpen] = useState(true) 
  let [isOpen, setIsOpen] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const [isAdded, setAdded] = useState(props.existing)
  const  [existingCart,setCart] = useState(props.existing)
  var product = props.item


  
  useEffect(() => {
    console.log('Card')
  }, [])

  function productStatus(e){
      var type = (<></>)
      if (e.status && isAdded == false){
        type =  ( <>
         {/* <a  href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                  </svg></a> */}
        <a  onClick={()=> addToCart(product)} href="javascript:void(0);" 
        className="rounded-full uppercase text-center   text-lg text-gray-600   hover:z-50"
        > Add to cart </a>
         {/* <a  href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg></a> */}
     
        </>)
      }else if (isAdded && e.status) {
        type = (
            <> 
       
          <a  onClick={()=> removeToCart(product)} href="javascript:void(0);" 
            className="rounded-full uppercase text-center   text-lg text-red-700   hover:z-50"
            >REMOVE </a>

<a  onClick={()=> removeToCart(product)} href="javascript:void(0);" 
            className="rounded-full uppercase text-center   text-lg text-red-700   hover:z-50"
            >
               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg></a>
            </>
          
        ) 
      }else {
        type = 
        <>    <a  disable={true} href="javascript:void(0);" className="rounded-full uppercase text-center  text-lg text-gray-300   hover:z-50 ">Out of Stock </a>
        </>
      
      }
      return type
  }
  function addToCart(e){
    props.didAdd(e)
    setAdded(true)
  }
  function removeToCart(e){
    props.didRemove(e)
    setAdded(false)
  }


  const cardDetails =  () => {
   return  (  <>
    <div className="w-100 flex justify-center items-center">
      <div className="w-full p-4">
      
          <div className="card flex flex-col justify-center p-10 bg-white rounded-md lg:hover:shadow-2xl transform transition duration-100 mx-auto md:mx-0"> 
          {/* hover:scale-110 hover:z-50  */}
          {/* {isAdded ?  <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500" /> : null }           */}
            <div className="prod-img">
                  <img src={product.imgUrl} className="w-full lg:h-auto object-cover object-center opacity-9"/>
                  
              </div>
              <div className="prod-info grid gap-5 " style={{marginTop:20}}>
                  <div>
               <div className="prod-title">
                  <p className="text-center  uppercase text-sm text-gray-900">
                  {product.title}
                  </p>
                  <p className="text-center  uppercase text-sm text-gray-400   hover:z-50 ">
                  {new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(product.price)}
                  </p>
              </div>
              <div className="prod-title">
              <SeeMore details={product}/>
              <div  className=" bg-gray-400 " style={{width:'100%',height:0.5}}/>
            
                  <div className="justify-center flex my-2"> 
                  
                  {/* <a  href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                  </svg></a> */}
                  
                
                   {productStatus(product)}
                   
                   {/* <a  href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg></a> */}
  
                  </div>
              </div>
              <div>
               </div>
                  </div>
              </div>
          </div>
          {/* <PopUp  title={product.title} subTitle={product.subtitle}/> */}
      </div>
     
  </div>
  </>
    )
  }
  return  <div>{cardDetails()}</div>
}