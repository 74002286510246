
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useHistory,Switch} from "react-router-dom";
import '.././App.css';
import { Tab } from '@headlessui/react'
import PaymentOption from '../component/PaymentOption'
import CustomCalendar from '../component/CustomCalendar'
import AddressPicker from '../component/AddressPicker'
import CartDetails from './Cart'
import { axios } from '../Network'

const flux = '60b1c9a9a001ef1e463d52c2' //'Flux-Bouquet'
const nidz = '5ff00ddaeb2f5d0940dfa186' // Dried

var id =  flux
// https://heroicons.com/
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MyModal() {

  let [categories] = useState({
    Delivery: [
        {
            id: 1,
            title: 'Does drinking coffee make you smarter?',
            date: '5h ago',
            name: 'CustomersName',
            isRequired:true,
            title:"Customer's Name",
            placeHolder:'Juan Dela Cruz',
            commentCount: 5,
            shareCount: 2,
            state:(e)=>{
                setCustomerName(e)
            },
            value:()=>{
                return customerName
            },
            },
            {
            id: 2,
            date: '2h ago',
            name: 'MobileNumber',
            isRequired:true,
            title:"Customer's Mobile Number",
            placeHolder:"09** **** ***",
            commentCount: 3,
            shareCount: 2,
            state:(e)=>{
                setCustomerMobile(e)
            } ,value:()=>{
                return customerMobile
            }
            },{
                id: 3,
                name: 'MobileNumber',
                isRequired:true,
                title:"Receiver's Name",
                placeHolder:'e.g. Juan Dela Cruz',
                date: 'Mar 19',
                commentCount: 24,
                shareCount: 12,  
                state:(e)=>{
                    setReceiverName(e)
                },value:()=>{
                    return receiverName
                }
            },{
            id:4,
            name: 'MobileNumber',
            isRequired:true,
            title:"Receiver's Mobile Number",
            placeHolder:"09** **** ***",
            date: 'Mar 19',
            commentCount: 24,
            shareCount: 12,
             state:(e)=>{
                setReceiverMobile(e)
            },value:()=>{
                return receiverMobile
            }
          }
          
    ],
    Pickup: [
        {
            id: 1,
            title: 'Does drinking coffee make you smarter?',
            date: '5h ago',
            name: 'CustomersName',
            isRequired:true,
            title:"Customer's Name",
            placeHolder:'Juan Dela Cruz',
            commentCount: 5,
            shareCount: 2,
            state:(e)=>{
                setCustomerName(e)
            },
            value:()=>{
                return customerName
            },
          },
          {
            id: 2,
            date: '2h ago',
            name: 'MobileNumber',
            isRequired:true,
            title:"Customer's Mobile Number",
            placeHolder:"09** **** ***",
            commentCount: 3,
            shareCount: 2,
            state:(e)=>{
                setCustomerMobile(e)
            } ,value:()=>{
                return customerMobile
            }
          },
    ]
  })

  let [isOpen, setIsOpen] = useState(true)
  const history = useHistory();  // do this inside the component
  const [enabled, setEnabled] = useState(false)
  const [store, setStoreDetails] = useState(null)
  const [payment, setSelectePayment] = useState('GCash')
  const [orderType, setOrderType] = useState(0) // 1- Delivery - 0 Pickup
  const [customerName, setCustomerName] = useState(null)
  const [customerMobile, setCustomerMobile] = useState(null)
  const [receiverName, setReceiverName] = useState(null)
  const [receiverMobile, setReceiverMobile] = useState(null)
  const [cart, setCartItems] = useState([])
  const [deliveryRate, setDeliveryRate] = useState({
    price:0
  })
  
  const [totalGrandPrice, setTotalGrandPrice] = useState(0)
  
  const goBack = () => history.goBack()
  function loadProductCategory() {
    let parameter = {
      id: id,
      equalTo: true, storeOwner: id, cType: "Product", cName: "Category"
    }
    axios
      .post("/details/Store", parameter)
      .then((response) => {
        setStoreDetails(response.data.results[0])
      })
      .catch(function (error) {
        console.log('fetching product', error)
      });
  }

  useEffect(() => {
    let existingCart = localStorage.getItem('UserCart')
    if (existingCart !== null) {
      let jsonCart = JSON.parse(existingCart)
      setCartItems(jsonCart)
      console.log(jsonCart)
    }
    loadProductCategory()
  }, [])

  const  validateDetails = ()=>{
alert('s')

  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    // this.setState({
    //     generatedID:result
    // })
    return result;
 }
 function successContent(){
    let path = `/`;
    localStorage.removeItem('UserCart')
    history.push(path);
 }
  function processOrder(e) {
    let id = makeid(10)  
     var finalPromoCode =''
        axios
        .put('/Order',{
           storeOwner:nidz,
           cType:"Order",
           cName:"Items",
            cart:cart,
            reference: id,
            resellersID: 'null',
            details: {
                customerName:customerName,
                contact:'Email',
                mobile:customerMobile,
                Receiver:receiverName,
                ReceiverContactNumber:receiverMobile,
                StreetAddress:'StreetAddress',
                orderType:orderType,
                // selectedDate:selectedDeliveryPickupDate,
                // formattedDate:moment(selectedDeliveryPickupDate).format('MMMM Do YYYY, h:mm:ss a'),
                deliveryNotes:'Notes',
                burialMessage:'BurialMessage',
                deliveryRateDetails:deliveryRate
            },
            storeDetails:store.storeContact,
            storeWebsite:store.Website,
            storeCodeName:store.codeName,
            payment:payment,
            total:getGrandTotalPrice(),
            code:'finalPromoCode'
        })
        .then(res =>   
        // alert('nice')
        successContent()
        
        
        ).catch(err => 
            alert('error')
        
        )
      
     
    }
  function getGrandTotalPrice (df){ 
    let initialPrice = 0
    try {
        var getTotalflatRate = orderType === 1 ? 0 : df
        cart.map(data => {  initialPrice += data.price * 1  }) 
        let service =  getTotalflatRate 
        return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(initialPrice + Number(service))
        
    }catch(error) { 
        // alert('error')
        console.log(error)
        // setTotalGrandPrice(0)
        return 0
    }
  
}
  const toggleView = ()=> {
      return (
        <Switch
        checked={enabled}
        onChange={setEnabled}
        className={`${
          enabled ? 'bg-blue-600' : 'bg-gray-200'
        } relative inline-flex items-center h-6 rounded-full w-11`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
      )
  }

function displayDF(){
    return (
        <h3 className="text-sm   font-medium ml-6 text-left  leading-5">
        Delivery Fee:              { new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(deliveryRate.price) }
       </h3>
    )
}
  return (
    <>
    
      <div className="min-h-screen px-4 text-center">
       
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
        
              <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl  overflow-auto" style={{height:'auto' ,overflow:'auto'}}>
                  <h3 className="text-sm    p-6 font-medium">
               Order  {orderType === 0  ? "Delivery": "Pick-up"} 
               {console.log(customerName,customerMobile,receiverName,receiverMobile)}
              </h3>
              <div className="w-full max-w-md px-2 py-2 sm:px-0">
              
      <Tab.Group 
      onChange={(e)=>setOrderType(e)}>
        <Tab.List className="flex p-1 space-x-1 bg-white-900/20 rounded-xl">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5  font-medium text-black  ring-black rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-black  ring-white  text-black ring-opacity-60',
                  selected
                    ? 'bg-black shadow text-white'
                    : 'text-black hover:bg-black/[0.9] hover:text-gray-400'
                )
              }
            > {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
        
            {store === null ? null : orderType === 0 ? 
             
             <div className=" w-full md:w-full px-3 mb-5  md:mb-0  mt-3 ml-4">
             <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
            Address
            </label>
            {/* setDeliveryRate */}
            <AddressPicker selected={(e)=>setDeliveryRate(e)} details={store}/>
            </div>  : null  } 
          {Object.values(categories).map((posts, idx) => (
              
            <Tab.Panel
              key={idx}
              className={classNames(
                'bg-white rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-white-400 ring-white ring-opacity-60'
              )}
            >   {console.log('type',idx)} {posts.map((post) => (
                    <>
                    <div className=" w-full md:w-full px-3 mb-6 md:mb-0">
                    <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                        {post.title}
                    </label>
                    <input key={post.id} value={post.value()} onChange={(e)=> post.state(e.target.value)} placeHolder={post.placeHolder} className="appearance-none block w-full bg-gray-100 text-gray-700 border  border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text"/>
                    {/* <p className=" text-red-500 text-xs italic">Please fill out this field.</p> */}
                </div> </>
                ))}


<label className=" block  ml-3 tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Customer Notes
                    </label>
<textarea className=" w-full px-3 py-2  ml-3 mr-3 text-gray-700 border rounded-lg focus:outline-none" placeholder="Customer notes" rows="4"/>

  <div className=" w-full md:w-full px-3 mb-6 mt-5 md:mb-0">
                    <label className=" block   tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
Date
                    </label>
                    <CustomCalendar selected={(e)=>console.log(e)}/>
                    </div>


              <div className="inline-block w-full  p-6 my-8 text-left align-middle transition-all transform " >
                  <h3 className="text-sm   font-medium leading-5">
                  Payment Method - {payment}
              </h3>
              <div className="w-full  py-2 sm:px-0">

                {store === null ?null :<PaymentOption details={store} selectePayment={(e)=>setSelectePayment(e.itemName)}/> }
                </div>  

                </div>
                 
                <div className="inline-block w-full  p-6 my-1 text-left align-middle transition-all transform " >
                  <h3 className="text-sm    font-medium leading-5">
                  Order Summary - {getGrandTotalPrice(0)}
              </h3>
              <div className="mt-2 bar overflow-auto" style={{height:  'auto',overflow:'auto'}}>
              <CartDetails items={cart} removeItem={(e)=>console.log(e)} showDelete={false}/>
                </div>
              </div>

            {orderType === 0 ?  displayDF() : null}

              <div className="inline-block w-full    p-6 text-left align-middle transition-all transform " >
                  <h3 className="text-sm font-medium leading-5">
                  Grand Total: {getGrandTotalPrice(deliveryRate.price)}
              </h3>
              <div className="mt-5 bar overflow-auto" >
            
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
               
                  {/* Payment successful 
            
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Your payment has been successfully submitted. We’ve sent
                    your an email with all of the details of your order.
                  </p>

                </div>


                <div style={{backgroundColor:'white',height:200}}>
      
                </div> */}
               
                <div className="mt-4">
             

                  <button

                    type="button"
                    className="inline-flex  w-100 justify-center px-4 py-2 text-sm font-medium   text-black bg-gray-100 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={goBack}
                  >

                    Cancel
                  <a href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" className=" h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg></a>
                  </button>
                  <button
                  disabled={cart.length  !== 0 ? false : true}
                    type="button"
                    className= {cart.length  !== 0 ? "inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-black border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500" :"inline-flex  justify-center px-4 py-2 text-sm font-medium ml-4 text-white bg-gray-200 border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"  }
                    onClick={()=>processOrder(null)}
                  >
                    Place order
                  <a href="/Cart" ><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg></a>
                  </button>
                </div>
              </div> 
          </div>
    </>
    
  )
}