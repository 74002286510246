import  React,{useEffect,useState}from 'react';
import logo from './logo.svg';
import './App.css';
import DD from './Sample'
import Form from './Form'
import Tran from './Tran'
import Modal from './Modal'
import Side from './SideSheet'
import Table from './Table'
import {Card,CardPrice,Toggle} from './component/'
import {axios} from './Network'
import ExternalCMS from 'axios'; 
import ReactJson from 'react-json-view' 
import ReactDiffViewer from 'react-diff-viewer';
const flux = '60b1c9a9a001ef1e463d52c2'
const nidz = '5ff00ddaeb2f5d0940dfa186'
const oldCode = `
const a = 10
const b = 10
const c = () => console.log('foo')
 
if(a > 10) {
  console.log('bar')
}
 
console.log('done')
`;
const newCode = `
const a = 10
const boo = 10
 
if(a === 10) {
  console.log('bar')
}
`;
 
function App() {
  const [scroll, setScroll] = useState(0)
  const [product, setProducts] = useState([])
  const [category, setSelectedCategory] = useState('Flux-Bouquet')
  const [categories, setCategory] = useState([])
  const [status, setStatus] = useState(false)
  const [dataObject, setLefctObject] = useState(null) //LEFT
  const [dataRightObject, setRightObject] = useState(null) //LEFT
  const [screen, setScreen] = useState([{}])
return (<div>
    TEST
<div class="flex justify-center items-center h-screen bg-gray-100 w-full">
      <div
        class="
          bg-white
          shadow-md
          h-96
          mx-3
          rounded-3xl
          flex flex-col
          justify-around
          items-center
          overflow-hidden
          sm:flex-row sm:h-52 sm:w-3/5
          md:w-96
        "
      >
        <img
          class="h-1/2 w-full sm:h-full sm:w-1/2 object-cover"
          src="https://images.unsplash.com/photo-1484101403633-562f891dc89a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
          alt="image"
        />

        <div
          class="
            flex-1
            w-full
            flex flex-col
            items-baseline
            justify-around
            h-1/2
            pl-6
            sm:h-full sm:items-baseline sm:w-1/2
          "
        >
          <div class="flex flex-col justify-start items-baseline">
            <h1 class="text-lg font-normal mb-0 text-gray-600 font-sans">
              Furniture
            </h1>
            <span class="text-xs text-indigo-300 mt-0">by supplier</span>
          </div>
          <p class="text-xs text-gray-500 w-4/5">
            Ergonimical for human body curv
          </p>
          <div class="w-full flex justify-between items-center">
            <h1 class="font-bold text-gray-500">$200</h1>
            <button
              class="bg-gray-700 mr-5 text-white px-3 py-1 rounded-sm shadow-md"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
</div>)
}
export default App;
