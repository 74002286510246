import { Fragment, useState,useCallBack } from 'react'
import { Dialog, Transition } from '@headlessui/react' 
import { Switch,Popover } from '@headlessui/react'
import { BeakerIcon ,ShoppingCartIcon} from '@heroicons/react/solid'
import PopUp from './PopUp'
import SeeMore from './SeeMore'
import { useEffect } from 'react'
const features = [
    { name: 'Photographer', description: '' },
    { name: 'Hosting', description: '' },
    { name: 'Makeup Artist', description: '' },
    { name: 'Patisserie', description: '' },
    { name: 'Venue', description: '' },
    { name: 'Others', description: '' },
  ]
  
  export default function Example() {
    return (
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Services Available</h2>
            <p className="mt-4 text-gray-500">
           With our world cutting edge technology  booking tools for event local business.
            </p>
  
            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
              {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                  <dt className="font-medium text-gray-900">{feature.name}</dt>
                  <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
              src="https://karenwillisholmes.com/wp-content/uploads/2020/01/abhinay-amp-krishanthy-20-1200x1800.jpg.webp"
              alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
              className="bg-gray-100 rounded-lg"
            />
            <img
              src="https://i0.wp.com/www.theweddingvowsg.com/wp-content/uploads/2016/08/atom-ungson-01.jpg?resize=589%2C393&ssl=1"
              alt="Top down view of walnut card tray with embedded magnets and card groove."
              className="bg-gray-100 rounded-lg"
            />
            <img
              src="https://images.summitmedia-digital.com/spotph/images/2020/03/06/the-fifth-at-rockwell-1583469469.jpg"
              alt="Side of walnut card tray with card groove and recessed card area."
              className="bg-gray-100 rounded-lg"
            />
            <img
              src="https://cdn.shopify.com/s/files/1/0396/5278/6339/products/StrawberryChantilly1_1600x.jpg?v=1621234189"
              alt="Walnut card tray filled with cards and card angled in dedicated groove."
              className="bg-gray-100 rounded-lg"
            />
          </div>
        </div>
      </div>
    )
  }