import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react' 

import DD from './Form'
import Form from './Form'
import Tran from './Tran'
import Modal from './Modal'
import TabSelector from './component/TabSelector'
export default function Example() {
  const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" auto-reopen="true" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                  <button onClick={() => setOpen(false)} type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Delete
                </button>
                    {/* <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      X
                    </button> */}
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <Dialog.Title className="text-lg font-medium text-gray-900">Panel title</Dialog.Title>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                   <TabSelector/>
                    <Form/>

{/* <div class="w-72 bg-white rounded-lg shadow-md p-6">
    <div class="w-16 mx-auto relative -mt-10 mb-3">
        <img class="-mt-1" src="https://www.fleursdujour.ph/wp-content/uploads/Large-Luxury-Box-with-Peach-Roses-6500-300x300.jpg" alt="cookie"/>
    </div>
    <span class="w-full sm:w-48  block leading-normal text-gray-800 text-md mb-3">
        We care about your data, and we&#x27;d love to use cookies to make your experience better.
    </span>
    <div class="flex items-center justify-between">
        <a class="text-xs text-gray-400 mr-1 hover:text-gray-800" href="#">
            Privacy Policy
        </a>
        <div class="w-1/2">
            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                See more
            </button>
        </div>
    </div>
</div> */}




<div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
    <div class="w-full h-full text-center">
        <div class="flex h-full flex-col justify-between">
            <svg width="40" height="40" class="mt-4 w-12 h-12 m-auto text-indigo-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                </path>
            </svg>
            <p class="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">
                Remove Item?
            </p>
            <p class="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">
                Are you sure you want to delete this card ?
            </p>
            <div class="flex items-center justify-between gap-4 w-full mt-8">
                <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Delete
                </button>
                <button type="button" class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-indigo-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>








<div class="flex flex-wrap items-center justify-center">
    <div class="flex-shrink-0 mx-2 mb-6 relative overflow-hidden bg-yellow-500 rounded-lg max-w-xs shadow-lg">
        <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="#f3c06b">
            </rect>
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#f3c06b">
            </rect>
        </svg>
        <div class="relative pt-10 px-10 flex items-center justify-center">
            <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
            </div>
            <picture>
                <source srcSet="https://www.pngitem.com/pimgs/m/244-2441637_campus-shoes-png-transparent-png.png" type="image/webp"/>
                    <source srcSet="https://www.fleursdujour.ph/wp-content/uploads/Large-Luxury-Box-with-Peach-Roses-6500-300x300.jpg"/>
                        <img class="relative w-40" src="https://www.fleursdujour.ph/wp-content/uploads/Large-Luxury-Box-with-Peach-Roses-6500-300x300.jpg" alt="shopping item"/>
                    </picture>
                </div>
                <div class="relative text-white px-6 pb-6 mt-6">
                    <span class="block opacity-75 -mb-1">
                        Indoor
                    </span>
                    <div class="flex justify-between">
                        <span class="block font-semibold text-xl">
                            Peace Lily
                        </span>
                        <span class="bg-white rounded-full text-yellow-500 text-xs font-bold px-3 py-2 leading-none flex items-center">
                            $36.00
                        </span>
                        
                    </div>
                </div>
            </div>
            <div class="flex-shrink-0 mx-2 mb-6 sm:mb-0 relative overflow-hidden bg-blue-500 rounded-lg max-w-xs shadow-lg">
                <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="#6da3fb">
                    </rect>
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#6da3fb">
                    </rect>
                </svg>
                <div class="relative pt-10 px-10 flex items-center justify-center">
                    <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
                    </div>
                    <img class="relative w-40" src="/images/object/6.png" alt="shopping"/>
                </div>
                <div class="relative text-white px-6 pb-6 mt-6">
                    <span class="block opacity-75 -mb-1">
                        Outdoor
                    </span>
                    <div class="flex justify-between">
                        <span class="block font-semibold text-xl">
                            Monstera
                        </span>
                        <span class="bg-white rounded-full text-blue-500 text-xs font-bold px-3 py-2 leading-none flex items-center">
                            $45.00
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex-shrink-0 mx-2 -mb-6 relative overflow-hidden bg-purple-500 rounded-lg max-w-xs shadow-lg">
                <svg class="absolute bottom-0 left-0 mb-8" viewBox="0 0 375 283" fill="none">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="#a17cf3">
                    </rect>
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="#a17cf3">
                    </rect>
                </svg>
                <div class="relative pt-10 px-10 flex items-center justify-center">
                    <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3">
                    </div>
                    <img class="relative w-40" src="/images/object/7.png" alt="shopping"/>
                </div>
                <div class="relative text-white px-6 pb-6 mt-6">
                    <span class="block opacity-75 -mb-1">
                        Outdoor
                    </span>
                    <div class="flex justify-between">
                        <span class="block font-semibold text-xl">
                            Oak Tree
                        </span>
                        <span class="bg-white rounded-full text-purple-500 text-xs font-bold px-3 py-2 leading-none flex items-center">
                            $68.50
                        </span>
                    </div>
                </div>
            </div>
        </div>








        <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-900 w-64 m-auto relative" style={{marginTop:120}}>
    <div class="w-full h-full text-center">
        <div class="flex h-full flex-col justify-between">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="mt-4 w-20 h-20 m-auto text-gray-800 dark:text-white" viewBox="0 0 2048 1792">
                <path d="M1920 768q53 0 90.5 37.5t37.5 90.5-37.5 90.5-90.5 37.5h-15l-115 662q-8 46-44 76t-82 30h-1280q-46 0-82-30t-44-76l-115-662h-15q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5h1792zm-1435 800q26-2 43.5-22.5t15.5-46.5l-32-416q-2-26-22.5-43.5t-46.5-15.5-43.5 22.5-15.5 46.5l32 416q2 25 20.5 42t43.5 17h5zm411-64v-416q0-26-19-45t-45-19-45 19-19 45v416q0 26 19 45t45 19 45-19 19-45zm384 0v-416q0-26-19-45t-45-19-45 19-19 45v416q0 26 19 45t45 19 45-19 19-45zm352 5l32-416q2-26-15.5-46.5t-43.5-22.5-46.5 15.5-22.5 43.5l-32 416q-2 26 15.5 46.5t43.5 22.5h5q25 0 43.5-17t20.5-42zm-1156-1217l-93 412h-132l101-441q19-88 89-143.5t160-55.5h167q0-26 19-45t45-19h384q26 0 45 19t19 45h167q90 0 160 55.5t89 143.5l101 441h-132l-93-412q-11-44-45.5-72t-79.5-28h-167q0 26-19 45t-45 19h-384q-26 0-45-19t-19-45h-167q-45 0-79.5 28t-45.5 72z">
                </path>
            </svg>
            <p class="absolute text-sm italic dark:text-white text-gray-800 top-2 right-2">
                by express
            </p>
            <p class="text-gray-900 dark:text-white text-lg mt-4">
                Package delivered
            </p>
            <p class="dark:text-gray-50 text-gray-700 text-xs font-thin py-2 px-6">
                Your package was delivered in 1 day and 4 hours by our postal partner
            </p>
        </div>
    </div>
</div>



<div class="flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="w-1/3 bg-cover bg-landscape">
    <img class="relative w-40" src="https://www.fleursdujour.ph/wp-content/uploads/Large-Luxury-Box-with-Peach-Roses-6500-300x300.jpg" alt="shopping item"/>
    </div>
    <div class="w-2/3 p-4">
        <h1 class="text-gray-900 font-bold text-2xl">
            Tomorow
        </h1>
        <p class="mt-2 text-gray-600 text-sm">
            You can&#x27;t buy your future, but you can do it. Money is nothing, you&#x27;r everything.
        </p>
        <div class="flex item-center mt-2">
            <svg class="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
                </path>
            </svg>
            <svg class="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
                </path>
            </svg>
            <svg class="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
                </path>
            </svg>
            <svg class="w-5 h-5 fill-current text-gray-500" viewBox="0 0 24 24">
                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
                </path>
            </svg>
            <svg class="w-5 h-5 fill-current text-gray-500" viewBox="0 0 24 24">
                <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
                </path>
            </svg>
        </div>
        <div class="flex item-center justify-between mt-3">
            <h1 class="text-gray-700 font-bold text-xl">
                $220
            </h1>
            <button class="px-3 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded">
                Add to Card
            </button>
        </div>
    </div>
</div>




<button class="btn">
      Version 2.7.0
      
  <div class="badge ml-2">new</div>
</button> 
<button class="btn btn-primary">
      Inbox
      
  <div class="badge ml-2 badge-outline">3</div>
</button> 
<button class="btn btn-secondary btn-outline">
      Notifications
      
  <div class="badge ml-2 badge-outline">+999</div>
</button> 
<button class="btn btn-accent">
      accent
      
  <div class="badge ml-2 badge-outline">new</div>
</button>

                  
                <Modal/>

                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}