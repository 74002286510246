import React, {useMemo,useState,useRef,memo} from 'react'
import DatePicker from "react-datepicker";
import moment from 'moment'
// import {toaster,TextareaField} from 'evergreen-ui'
import "react-datepicker/dist/react-datepicker.css";
import { compareAsc, format,setHours ,setMinutes} from 'date-fns'
const storeColor = 'black'
export default function DateMPicker({selected,currentDate}) {
 
    const [ startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 0), 9));
    const [statusError, setStatus] = useState(false);
    
  const scrollViewRef = useRef();
    const newDate = () =>{
        var new_date = moment(new Date());  
        return  new_date.add(1, 'days');
    }
    
    const filterPassedTime = time => {
      var current = new Date();
      var followingDay = new Date(current.getTime() ); // + 1 day in ms
        const currentDate = followingDay
        const selectedDate = new Date(time) 
        return currentDate.getTime() < selectedDate.getTime();
      }
 
    const calendarContent = useMemo (() => {
      moment('05-17-2018 11:40 PM', 'MM-DD-YYYY hh:mm A')
    return (
      <DatePicker 

  locale="ph"
      inline
      
      minDate={moment(new Date(), "Asia/Manila").toDate()}
  onChange={(date) => setStartDate(date)}
  showTimeSelect
  timeIntervals={60}
  filterTime={filterPassedTime}
  customInput={  <button   className="primary-btn cart-btn" style={{borderRadius:5,width:'100%',borderRadius:5,backgroundColor:storeColor,color:'white'}  }>
     
  {moment(startDate, "Asia/Manila").format('MMMM Do YYYY, h:mm:ss a')}
</button>}
  excludeTimes={[
    setHours(setMinutes(new Date(), 0), 0),
    setHours(setMinutes(new Date(), 0), 1),
    setHours(setMinutes(new Date(), 0), 2),
    setHours(setMinutes(new Date(), 0), 3),
    setHours(setMinutes(new Date(), 0), 4),
    setHours(setMinutes(new Date(), 0), 5),
    setHours(setMinutes(new Date(), 0), 6),
    // setHours(setMinutes(new Date(), 0), 7),
    // setHours(setMinutes(new Date(), 0), 8),
    setHours(setMinutes(new Date(), 0), 1),
    setHours(setMinutes(new Date(), 0), 2),
    setHours(setMinutes(new Date(), 0), 3),
    // setHours(setMinutes(new Date(), 0), 18),
    setHours(setMinutes(new Date(), 0), 19),
    setHours(setMinutes(new Date(), 0), 20),
    setHours(setMinutes(new Date(), 0), 21),
    setHours(setMinutes(new Date(), 0), 22),
    setHours(setMinutes(new Date(), 0), 23),
  ]}
  dateFormat="MMMM d, yyyy h:mm aa"
  selected={startDate}
   onChange={date => selecteds(date)} />
    )
    },[startDate])
 

    function selecteds (time) {
      console.log("selected", time)
if (moment(time).isSame(moment(), 'day') ==false) {
  
        selected(time)
        setStartDate(time)  
        setStatus(false)
}else {
  setStatus(true)
  selected(null)
  setStartDate(setHours(setMinutes(new Date(), 0), 8))
//   toaster.danger('Please select future date')
}

      }


    return (
      
        <div>  
         {calendarContent}
          {statusError ? 

          
    <React.Fragment>
      {/* <TextareaField
      label="To ensure quality, we do not offer same day delivery."
      style={{display:'none'}}
        required
        validationMessage="Please select future date"
      /> */}
      </React.Fragment>
      : null}
        </div>
    )
}
// })

// export default  CustomDate