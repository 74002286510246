import React from 'react';
import Store from './Store';
import CD from './component/CustomerDetails';
import CMS from './CMS';
import Test from './Test';
import { BrowserRouter, Route, Link,Switch } from "react-router-dom"; 

// import Test from './Te';
const RouterManager = () => (   
  <BrowserRouter>
    <Switch>   
      <Route exact path="/:type?" component={Store}/> 
      <Route exact path="/Cart" component={CD}/> 
    </Switch> 
</BrowserRouter> 
)


export default RouterManager;
